*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scrollbar-width: none;
}
.DraftEditor-editorContainer {
  min-height: 100px;
}
body {
  margin: 0;

  font-family: "poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff4f4;
  font-size: 1.6rem;
  scrollbar-width: none !important;
  line-height: 1.5;
}
.root {
  scrollbar-width: none;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* Cropper Styles */
.rounded .cropper-view-box,
.rounded .cropper-face {
  border-radius: 50% !important;
}

.rectangle .cropper-view-box,
.rectangle .cropper-face,
.square .cropper-view-box,
.square .cropper-face {
  border-radius: 0% !important;
}
/* perfect scrollbar visible  */
.ps .ps__rail-x {
  opacity: 0.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}
.align-right {
  display: flex !important;
  justify-content: right !important;
}

.back {
  padding-left: 2rem;
}
.select-inline {
  padding: 5px;
  color: #4b5563;
  background-color: #fff4f4;
  border: 0px;
  border-radius: 5px;
  margin: 0.8rem 0;
}

.more {
  color: rgb(12, 92, 171) !important;
}
